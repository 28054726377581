import { jwtDecode } from 'jwt-decode';
import { User } from '~/types/user';

export default defineNuxtRouteMiddleware((to) => {
    const token = localStorage.getItem('authToken');
    const CURRENT_TIME_DIVIDER = 1000;
    if (token)
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / CURRENT_TIME_DIVIDER;

            if (decodedToken.exp && decodedToken.exp < currentTime)
                localStorage.removeItem('authToken');
            else
                useUser().value = decodedToken as User;
        } catch (error) {
            // Handle token decoding error
            localStorage.removeItem('authToken');
        }
    else if (to.name !== 'login')
        navigateTo(
            '/',
            {
                external: true

            }
        );
});
