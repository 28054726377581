import { logErrorToServer } from '~/utils/logErrorToServer';

export default defineNuxtPlugin(() => {
    if (process.client) {
        // Global JavaScript Error Handler
        window.onerror = (
            message: string | Event,
            source?: string,
            lineno?: number,
            colno?: number,
            error?: Error
        ) => {
            logErrorToServer({
                type: 'js-error',
                message: message.toString(),
                source,
                lineno,
                colno,
                stack: error?.stack || null
            });
        };

        // Global Promise Rejection Handler
        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            logErrorToServer({
                type: 'unhandledrejection',
                message: event.reason?.message || 'Unhandled rejection',
                stack: event.reason?.stack || null
            });
        };
    }
});
