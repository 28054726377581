import { logErrorToServer } from '~/utils/logErrorToServer';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (
        error: unknown,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        vm: any,
        info: string
    ) => {
        const componentName = vm?.$options?.name || 'UnknownComponent';

        logErrorToServer({
            type: 'vue-error',
            message: error instanceof Error ? error.message : String(error),
            stack: error instanceof Error ? error.stack : null,
            info,
            component: componentName
        });
    };
});
