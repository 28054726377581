<template>
    <div>
        <VitePwaManifest />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
const showSpinner = ref(false);
const displayOnLoad = ref(true);
const nuxtApp = useNuxtApp();

useHead({
    bodyAttrs: {
        class: 'bg-neutral-100'
    }
});

nuxtApp.hook(
    'page:start',
    () => {
        showSpinner.value = true;
    }
);

nuxtApp.hook(
    'page:finish',
    () => {
        showSpinner.value = !showSpinner.value;
    }
);

onMounted(() => {
    displayOnLoad.value = false;
    registerServiceWorker();
});

const registerServiceWorker = async() => {
    if ('serviceWorker' in navigator)
        await navigator.serviceWorker
            .register('/worker.js');
};
</script>
