/* eslint-disable no-magic-numbers */
type ErrorLog = {
    type: 'js-error' | 'vue-error' | 'unhandledrejection';
    message: string;
    stack?: string | null;
    source?: string;
    lineno?: number;
    colno?: number;
    info?: string;
    component?: string;
  };

export const logErrorToServer = async(error: ErrorLog) => {
    // Explicitly used any here because navigator type is not present in TS
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { connection, deviceMemory, userAgentData } = (navigator as any);
    const deviceInfo = {
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        windowOuterWidth: window.outerWidth,
        windowOuterHeight: window.outerHeight,
        windowZoomLevel: (window.outerWidth / window.innerWidth).toFixed(2),

        screenWidth: screen.width,
        screenHeight: screen.height,
        screenAvailableWidth: screen.availWidth,
        screenAvailableHeight: screen.availHeight,
        screenHasTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0,

        devicePixelRatio: window.devicePixelRatio,
        deviceMemory: deviceMemory || 'Unknown',

        screenOrientation: window.screen.orientation ? window.screen.orientation.type : 'Unknown',
        language: navigator.language,
        userAgentData: userAgentData || navigator.userAgent,

        networkInfo: connection
            ? {
                downlink: connection.downlink,
                effectiveType: connection.effectiveType,
                rtt: connection.rtt
            }
            : 'Unknown',

        scrollPosition: {
            scrollX: window.scrollX,
            scrollY: window.scrollY
        }
    };
    try {
        const permissionStatus = await navigator.permissions.query({
            name: 'geolocation'
        });
        await fetch(
            `${useRuntimeConfig().public.apiBase}/log-error`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...error,
                    deviceInfo,
                    isLoggedIn: isLoggedIn(),
                    user: useUser().value,
                    notificationPermission: ('Notification' in window) ? Notification.permission : 'Notification not suppored',
                    locationPermission: permissionStatus.state
                })
            }
        );
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(
            'Failed to log error to server:',
            err
        );
    }
};
