import { jwtDecode } from 'jwt-decode';
import { UserPermissionType } from '../../shared/shared.interface';
import { User } from '~/types/user';
const SEC_TO_MS = 1000;

export const useIsAuthenticated = () => {
    const token = localStorage.getItem('authToken');

    if (token)
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / SEC_TO_MS;

            if (decodedToken.exp && decodedToken.exp < currentTime) {
                localStorage.removeItem('authToken');
                return false;
            }
        } catch (error) {
            // Handle token decoding error
            localStorage.removeItem('authToken');
            return false;
        }

    return Boolean(token);
};

export const useAuthToken = () => {
    const token = localStorage.getItem('authToken');

    if (token)
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / SEC_TO_MS;

            if (decodedToken.exp && decodedToken.exp < currentTime) {
                localStorage.removeItem('authToken');
                return null;
            }
        } catch (error) {
            // Handle token decoding error
            localStorage.removeItem('authToken');
            return null;
        }

    return token;
};

export const useIsAdminUser = () => {
    let isAdminUser = false;
    const token = localStorage.getItem('authToken');

    if (token) {
        const decodedToken: User = jwtDecode(token);
        const { permissions } = decodedToken;
        if (permissions.length) {
            const adminUserPermission = permissions.filter(permission => permission.name === UserPermissionType.MANAGE_USERS as string);
            if (adminUserPermission.length)
                isAdminUser = true;
        }
    }

    return isAdminUser;
};
