export default defineNuxtPlugin((nuxtApp) => {
    const api = $fetch.create({
        baseURL: useRuntimeConfig().public.apiBase,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onRequest({ request, options, error }) {

            /*
             * Note that this relies on ofetch >= 1.4.0 - you may need to refresh your lockfile
             * const headers = options.headers as Headers;
             * headers.set(
             *     'Authorization',
             *     `Bearer ${session.value?.token}`
             * );
             */
        },
        async onResponseError({ response }) {
            // eslint-disable-next-line no-magic-numbers
            if (response.status === 401)
                await nuxtApp.runWithContext(() => navigateTo('/login'));
        }
    });

    // Expose to useNuxtApp().$api
    return {
        provide: {
            api
        }
    };
});
